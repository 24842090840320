<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Tulis Baru
            </div>
          </template>

          <v-form
            v-model="disable_state"
            @submit.prevent="setPost"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="title"
                    :rules="titleRules"
                    label="Judul"
                    class="purple-input"
                    prepend-icon="mdi-pencil-plus"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <!-- <v-select
                    v-model="category_id"
                    label="Kategori"
                    class="purple-input"
                    prepend-icon="mdi-format-list-checks"
                    :items="category"
                    item-text="name"
                    item-value="id"
                  >

                  </v-select> -->
                  <v-select
                    v-model="category_id"
                    :items="category"
                    :rules="categoryRules"
                    class="purple-input"
                    prepend-icon="mdi-format-list-checks"
                    label="Pilih Kategori"
                    item-text="name"
                    item-value="id"
                  >
                    <!-- Template for render data when the select is expanded -->
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <!-- Divider and Header-->
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <!-- Normal item -->
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name" />
                          <v-list-item-subtitle
                            v-html="
                              data.item.parent ? data.item.parent.name : ''
                            "
                          />
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <!-- Tangggal Mulai -->
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startEvent"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startEvent"
                        label="Tgl.Mulai"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startEvent"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startEvent)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                >
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time"
                        label="Jam Mulai"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="menu1"
                      v-model="time"
                      full-width
                    >
                      <div>
                        <v-btn
                          text
                          @click="$refs.menu1.save(time)"
                        >
                          save
                        </v-btn>
                      </div>
                    </v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- Tanggal Selesai -->
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="endEvent"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endEvent"
                        label="Tgl.Selesai"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endEvent"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(endEvent)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <!--  -->
                  <v-menu
                    ref="jamSelesai"
                    v-model="jamSelesai"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time1"
                        label="Jam Selesai"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="jamSelesai"
                      v-model="time1"
                      full-width
                    >
                      <div>
                        <v-btn
                          text
                          @click="$refs.jamSelesai.save(time1)"
                        >
                          save
                        </v-btn>
                      </div>
                    </v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- Jam Selesai -->
              <v-row>
                <v-col>
                  <v-select
                    v-model="polling_id"
                    label="Pilih Polling"
                    :items="polling.data"
                    prepend-icon="mdi-certificate"
                    item-text="question"
                    item-value="id"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="location"
                    :rules="titleRules"
                    label="Lokasi"
                    class="purple-input"
                    prepend-icon="mdi-pencil-plus"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-file-input
                    v-model="file1"
                    :state="Boolean(file1)"
                    :loading="upload_state"
                    :disabled="disable_state"
                    counter
                    show-size
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    placeholder="Unggah Gambar Thumbnail"
                    @change="uploadImage"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="post"
                    :rules="postRules"
                    class="purple-input"
                    label="Artikel Teks"
                    prepend-icon="mdi-square-edit-outline"
                    auto-grow
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    :disabled="!disable_state"
                    @click="setPost"
                  >
                    POSTING
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')

  export default {
    name: 'CreateNews',
    data () {
      return {
        time: null,
        time1: null,
        menu2: false,
        jamSelesai: false,
        menu1: false,
        menu: false,
        minDate: '2019-07-04',
        maxDate: '2019-08-30',
        polling_id: '',
        polling: {
          meta: {},
          data: [],
        },
        textFieldProps: {
          prependIcon: 'mdi-calendar',
        },
        timeProps: {
          useSeconds: false,
          ampmInTitle: true,
        },
        title: '',
        thumbnail_id: 4,
        image_id: 4,
        post: '',
        featured: 0,
        location: '',
        start_at: null,
        end_at: null,
        community_id: 1,
        sorting: 1,
        status: 'publish',
        status_items: ['publish', 'draft', 'review'],
        type: 'news',
        file1: null,
        category: [
          {
            id: 1,
            name: 'Komunitas',
            type: 'news',
            status: 'publish',
            deleted_at: null,
            created_at: '2020-11-28T08:11:52.000000Z',
            updated_at: '2020-11-28T08:11:52.000000Z',
            community_id: 1,
            category_id: null,
            parent: {
              id: 2,
              name: 'Terbaru',
              type: 'news',
              status: 'publish',
              deleted_at: null,
              created_at: '2020-11-28T08:12:05.000000Z',
              updated_at: '2020-11-28T08:12:05.000000Z',
              community_id: 1,
              category_id: 1,
            },
          },
        ],
        upload_state: false,
        disable_state: false,
        category_id: null,
        selected: null,
        startEvent: new Date().toISOString().substr(0, 10),
        endEvent: new Date().toISOString().substr(0, 10),
        titleRules: [value => value !== '' || 'wajib diisi'],
        categoryRules: [value => value !== '' || 'Kategori wajib diisi'],
        postRules: [
          value => value !== '' || 'post wajib diisi',
          value => value.length >= 4 || 'password minimal 4 carakter',
        ],
      }
    },
    mounted () {
      this.getCategories()
      this.getPolling()
    },
    methods: {
      setPolling (data) {
        this.polling = data
      },
      getPolling () {
        axios
          .get('/v1/polling?sort=-id&entities=user,community,options')
          .then(response => {
            this.setPolling(response.data)
          })
      },
      save (startEvent) {
        this.$refs.menu.save(startEvent)
      },
      saveEnd (endEvent) {
        this.$refs.menu.save(endEvent)
      },
      setPost () {
        this.disable_state = true
        axios
          .post('/v1/post/event', {
            title: this.title,
            thumbnail_id: this.image_id,
            image_id: this.image_id,
            post: this.post,
            featured: 1,
            community_id: this.$store.state.user.community_id,
            sorting: 99,
            status: 'publish',
            start_date: this.startEvent,
            start_time: this.time,
            end_date: this.endEvent,
            end_time: this.time1,
            category_id: this.category_id,
            location: this.location,
            polling_id: this.polling_id,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.disable_state = false
              this.image_id = response.data.data.id
              this.$router.push({ name: 'ListEvent' })
            } else {
              console.log(response.data.meta.message)
            }
          // console.log("image upload response > ", response.data);
          })
      // console.log(this.time)
      // console.log(this.startEvent)
      // console.log(this.endEvent)
      // console.log(this.time1)
      },
      upload (event) {
        this.upload_state = true
        console.log(event.target.files[0])
      },
      uploadImage (event) {
        if (this.file1 === null) {
          this.upload_state = false
          this.disable_state = false
          return 1
        }
        const URL = '/v1/media'
        this.upload_state = true
        const data = new FormData()
        data.append(
          'path',
          'image/news/community/' + this.$store.state.user.community_id,
        )
        data.append('file', this.file1)
        const config = {
          header: {
            'Content-Type': 'image/png',
          },
        }

        axios.post(URL, data, config).then(response => {
          if (response.data.meta.status) {
            this.upload_state = false
            this.disable_state = false
            this.image_id = response.data.data.id
          } else {
            this.upload_state = false
            this.disable_state = false
          }
        })
      },
      getCategories () {
        axios
          .get('/v1/category?only_parent=0&sort=id&entities=parent&type=event')
          .then(response => {
            if (response.data.meta.status) {
              this.category = response.data.data
            } else {
              console.log(response.data.meta.message)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
